.competences__soft-skills {
    margin-top: 4rem;
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }
}

.skill-card.highlight {
  border-color: #007bff;
  background-color: #e9f5ff;
}