/* Styles de base pour les grands écrans */
.projects-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Styles pour les sections */
  section {
    margin-bottom: 40px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  /* Écrans de 992px ou plus petits */
  @media (max-width: 992px) {
    .projects-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Écrans de 768px ou plus petits */
  @media (max-width: 768px) {
    .projects-container {
      grid-template-columns: 1fr;
    }
  }
  
  /* Écrans de 576px ou plus petits */
  @media (max-width: 576px) {
    .projects-container {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }
  
  /* Écrans de 350px ou plus petits */
  @media (max-width: 350px) {
    .projects-container {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
  
  