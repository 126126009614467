.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  width: 90%;
  height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 1.5rem;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.modal-header {
  margin: 20px;
}

.modal-body {
  flex: 1;
  margin: 20px;
  overflow-y: auto;
  text-align: justify;
}

.modal-image {
  max-width: 15%;
  height: auto;
  margin-bottom: 20px;
}

.modal-details ul {
  list-style-type: none;
  padding: 0;
}

.little__link {
  color: var(--title-color);
}

@media (max-width: 768px) {
  .close-button {
    font-size: 1.5rem;
  }

  .modal-header,
  .modal-body {
    margin: 10px;
  }
}

  
  .services__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.services__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.services__button:hover .services__button-icon {
    transform: translateX(00.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.services__modal-content {
  width: 500px;
  position: relative;
  background-color: var(--container-color);
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 1.5rem;
}

.services__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.active-modal {
  opacity: 1;
  visibility: visible;
}

.modal-details {
  display: flex;
  flex-direction: column;
}

.modal-details ul {
  list-style: none;
  padding: 0;
}

.modal-details ul li {
  margin-bottom: 5px;
}