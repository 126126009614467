.skill-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 50%;
}

.skill-container ul {
  list-style-type: none;
  padding: 0;
}
@media (max-width: 768px) {
    .skill-container {
      max-width: 90%;
    }
  }