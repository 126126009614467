.card {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.card.highlight {
  border-color: #007bff;
  background-color: #e9f5ff;
}
.hand-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.card-header {
  display: flex;
  align-items: center;
}

.card-image {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  /*border-radius: 8px;
  object-fit: cover;*/
}

.summary{
  color: var(--title-colo);
  margin-bottom: 1.25rem;
}

.card-content {
  flex: 1;
  text-align: center;
}

.card.expanded .card-content {
  flex: 2;
}

.card-details {
  flex: 1;
  margin-left: 20px;
}

.card.expanded {
  display: flex;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 20px;
}

.card.expanded .card-header {
  align-items: flex-start;
}

.card.expanded .card-image {
  width: 150px;
  height: 150px;
}

.card.expanded .card-content {
  text-align: left;
}

.card.expanded .card-details {
  display: flex;
  flex-direction: column;
}

.card-details ul {
  list-style: none;
  padding: 0;
}

.card-details ul li {
  margin-bottom: 5px;
}

.card.expanded .hand-icon {
  display: none;
}
@media (max-width: 1200px) {
  .card-image {
    width: 80px;
    height: 80px;
    margin-right: 15px;
  }

  .card {
    padding: 15px;
  }
}

@media (max-width: 992px) {
  .card-image {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  .card {
    padding: 10px;
  }
}

@media (max-width: 768px) {


  .hand-icon{
    visibility: hidden;
  }
}

@media (max-width: 576px) {
  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .card-details {
    margin-left: 0;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }

  .card.expanded .card-content {
    text-align: center;
  }
}

@media (max-width: 350px) {
  
  .card-image {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .card.expanded .card-content {
    font-size: 0.9em;
  }

  .card.expanded .card-details ul li {
    font-size: 0.9em;
  }
}
/* styles.css */

/* Styles de base pour la carte */
.skill-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 450px; /* Largeur minimale ajustable */
}

.skill-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.skill-card-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.skill-card-header h2 {
  margin: 0 0 0 10px;
  font-size: 1.2em;
  color: #333;
  flex: 1;
  word-wrap: break-word;
}

.skill-card-image {
  width: 50px;
  height: 50px;
  /*border-radius: 50%;*/
  object-fit: cover;
  flex-shrink: 0;
}

.skill-card-body {
  margin-top: 10px;
  flex: 1;
}

.skill-card-body p {
  margin: 0 0 10px 0;
  color: #555;
}

.skill-card-body ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #555;
}

.skill-card-body ul li {
  margin-bottom: 5px;
}

/* Styles de base pour le conteneur */
.app {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}

/* Tailles d'écran */

@media (max-width: 576px) {
  .skill-card {
    width: 100%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .skill-card {
    width: 48%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .skill-card {
    width: 32%;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .skill-card {
    width: 24%;
  }
}

@media (min-width: 1201px) {
  .skill-card {
    width: 20%;
  }
}
