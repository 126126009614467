.contact__container {
 max-width: 768px;
}

.contact__link {
    color:var(--title-color)
}

.contact__link:hover {
    color: var(--title-color-dark);
}