.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    height: 90%;
    width: 90%;
    max-width: 90%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-header {
    margin-bottom: 10px;
  }
  
  .modal-image {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .modal-body-Project {
    text-align: justify;
  }
  
  .modal-details {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
  }
  .modal-details ul {
    list-style-type: none;
    padding: 0;
  }